import React, {useEffect,useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';
import NumberFormat from "react-number-format";
import {convertFeetToInches, uniqid, validateNumberFormat} from "../_helpers";
import {fieldTypes, virtualSizerBrand, virtualSizerClient, virtualSizerKey, virtualSizerUrl, recordSizeApiKey, recordSizeEndpoint} from "../_constants";

import bodySlim from '../img/mannequins/body-slim.png'
import bodyAverage from '../img/mannequins/body-average.png'
import bodyLarger from '../img/mannequins/body-larger.png'
import bodyFemaleSlim from '../img/mannequins/body-female-slim.png'
import bodyFemaleAverage from '../img/mannequins/body-female-average.png'
import bodyFemaleLarger from '../img/mannequins/body-female-larger.png'
import bellyFlat from '../img/mannequins/belly-flat.png'
import bellyAverage from '../img/mannequins/belly-average.png'
import bellyLarger from '../img/mannequins/belly-larger.png'
import braSmaller from '../img/mannequins/bra-smaller.png'
import braAverage from '../img/mannequins/bra-average.png'
import braLarger from '../img/mannequins/bra-larger.png'
import StyledSelect from "../components/formFields/styledSelect";
import StyledNumberInput from "../components/formFields/styledNumberInput";
import ImageSelect from "../components/formFields/imageSelect";

const maxShoeSize = 16;
const minShowSize = 7;

const formOptions={
	sex:[
		{label:'Male',value:'male'},
		{label:'Female',value:'female'}
	],
	fit:[
		{label:'Relaxed',value:'relaxed'},
		{label:'Fitted',value:'fitted'}
	],
	bodyType:[
		{label:'SLIM',value:'slim',imgSrc:bodySlim},
		{label:'Average',value:'average',imgSrc:bodyAverage},
		{label:'Larger',value:'larger',imgSrc:bodyLarger}
	],
	bodyTypeFemale:[
		{label:'SLIM',value:'slim',imgSrc:bodyFemaleSlim},
		{label:'Average',value:'average',imgSrc:bodyFemaleAverage},
		{label:'Larger',value:'larger',imgSrc:bodyFemaleLarger}
	],
	bellyType:[
		{label:'FLAT',value:'flat',imgSrc:bellyFlat},
		{label:'Average',value:'average',imgSrc:bellyAverage},
		{label:'Larger',value:'larger',imgSrc:bellyLarger}
	],
	braSize:[
		{label:'Smaller',value:'smaller',imgSrc:braSmaller},
		{label:'Average',value:'average',imgSrc:braAverage},
		{label:'Larger',value:'larger',imgSrc:braLarger}
	],
	bandSize: [
		{ label: '28', value: '28' },
		{ label: '30', value: '30' },
		{ label: '32', value: '32' },
		{ label: '34', value: '34' },
		{ label: '36', value: '36' },
		{ label: '38', value: '38' },
		{ label: '40', value: '40' },
		{ label: '42', value: '42' },
		{ label: '44', value: '44' },
		{ label: '46', value: '46' },
		{ label: '48', value: '48' },
		{ label: '50', value: '50' },
		{ label: '52', value: '52' },
		{ label: '54', value: '54' },
		{ label: '56', value: '56' }
	],
	cupSize: [
		{ label: 'AA', value: 'AA' },
		{ label: 'A', value: 'A' },
		{ label: 'B', value: 'B' },
		{ label: 'C', value: 'C' },
		{ label: 'D', value: 'D' },
		{ label: 'DD', value: 'DD' },
		{ label: 'E', value: 'E' },
		{ label: 'DDD', value: 'DDD' },
		{ label: 'F', value: 'F' },
		{ label: 'G', value: 'G' },
		{ label: 'H', value: 'H' },
		{ label: 'I', value: 'I' },
		{ label: 'J', value: 'J' },
		{ label: 'K', value: 'K' },
		{ label: 'L', value: 'L' },
		{ label: 'M', value: 'M' },
		{ label: 'N', value: 'N' },
		{ label: 'O', value: 'O' },
	]
}

export default function SizerScreen()
{
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [sizeResults,setSizeResults] = useState(null);
	const [updateKey,setUpdateKey] = useState();
	const [selectedSex,setSelectedSex] = useState(null);
	const [formFields,setFormFields] = useState(
		{
			first_name:{label:'First Name',value:'',type:fieldTypes.text, placeholder:'Enter First Name'},
			last_name:{label:'Last Name',value:'',type:fieldTypes.text,placeholder:'Enter Last Name'},
			fire_dept:{label:'Fire Dept',value:'',type:fieldTypes.text,placeholder:'Enter Fire Dept'},
			sex:{label:'Gender',value:'',type:fieldTypes.select, optionsKey:'sex',placeholder:'Select Sex',changeHandler:setSelectedSex},
			age:{label:'Age',value:'',type:fieldTypes.number,placeholder:'Enter Age'},
			height:{label:'Height',value:'',type:fieldTypes.number,format:'#\'##',mask:'_',placeholder: 'Enter Height',formatted:true},
			weight:{label:'Weight',value:'',type:fieldTypes.number,placeholder:'Enter Weight (lbs)', suffix: ' lbs'},
			shoe_size:{label:'Shoe Size',value:'', type:fieldTypes.number, decimals:1,placeholder:'Enter Shoe Size'},
			waist_circum_preferred:{label:'Jean Waist',value:'',type:fieldTypes.number, format:'##',mask:'_',placeholder:'Enter Waist (in.)',suffix:' in', sexRequired:'male'},
			inseam:{label:'Jean Inseam',value:'', type:fieldTypes.number, placeholder:'Enter Length (in.)', suffix:' in', sexRequired:'male'},
			desired_garment_fit:{label:'Fit Preference',value:'',type:fieldTypes.select, optionsKey:'fit',placeholder:'Select Preference'},
			band_size: { label: 'Band Size', value: '', type: fieldTypes.select, optionsKey: 'bandSize', placeholder: 'Select Band Size', sexRequired: 'female' },
			cup_size: { label: 'Cup Size', value: '', type: fieldTypes.select, optionsKey: 'cupSize', placeholder: 'Select Cup Size', sexRequired: 'female' },
			body_type:{label:'Body Type',value:'', type:fieldTypes.imageSelect, placeholder:'Select Body Type', optionsKey:'bodyType'},
			belly_type:{label:'Belly Type',value:'', type:fieldTypes.imageSelect, placeholder:'Select Belly Type', optionsKey:'bellyType', sexRequired:'male'},
		}
	);

	const scrollToTop = () =>{
		window.scrollTo(0, 0);
	}

	const clearForm = () =>{
		setSizeResults(null);
		Object.entries(formFields).forEach((entry)=>{
			entry[1].value = '';
		})
		setFormFields(formFields);
	}

	const updateFormField = (key,value) =>{
		if(formFields[key]) {
			formFields[key].value = value || '';
			formFields[key].error = !value;

			if (! formFields[key].error) {
				if (formFields[key].pattern) {
					let pattern = new RegExp(formFields[key].pattern);
					formFields[key].error = ! pattern.test(value);
				}
			}

			setFormFields(formFields);
			setUpdateKey(Math.random())
			if(formFields[key].changeHandler){
				formFields[key].changeHandler(value);
			}
			setTimeout(()=>{
				validateFormObject(formFields);
				setFormFields(formFields);
			},300)
		}
	};

	const getBraSize = (formData = null) =>{
		if(!formData){
			formData = formFields;
		}
		return (formData.band_size?.value || '') + (formData.cup_size?.value || '');
	}

	const getQueryString =(formData) =>{
		let qString = '';
		Object.entries(formData).forEach((entry)=>{
			if(!entry[1] || (entry[1].sexRequired && entry[1].sexRequired !== selectedSex)){
				return;
			}
			if(entry[0] === 'band_size' || entry[0] === 'cup_size'){
				return;
			}
			if(entry[0]==='height'){
				qString += entry[0] + '=' + convertFeetToInches(entry[1].value) + '&';
			}else if (entry[0] !== 'first_name' && entry[0] !== 'last_name') {
				if(entry[0] === 'shoe_size'){
					if(entry[1].value < minShowSize || entry[1].value > maxShoeSize){
						return;
					}
				}
				qString += entry[0] + '=' + entry[1].value + '&';
			}
		});
		if(selectedSex === 'female'){
			qString += 'bra_size=' + getBraSize(formData) + '&';
		}
		qString = qString.substr(0,qString.length-1);

		return qString;
	}

	const validateFormObject = (formObject,missingFields = []) =>{
		Object.entries(formObject).forEach((entry)=>{
			if(!entry[1]){
				return;
			}
			if(entry[1].optional){
				formObject[entry[0]].error = false;
				return;
			}
			if(entry[1].sexRequired && entry[1].sexRequired !== selectedSex){
				formObject[entry[0]].error = false;
				return;
			}
			if(!entry[1].value){
				formObject[entry[0]].error = true;
				missingFields.push(entry[0]);
			}else if(entry[1].type && entry[1].type === 'number' && entry[1].format){
				if(!!entry[1].formatted){
					entry[1].value = entry[1].value.replace('_','')
				}
				if(!validateNumberFormat(entry[1].value,entry[1].format, !!entry[1].formatted)){
					formObject[entry[0]].error = true;
					missingFields.push(entry[0]);
				}else{
					formObject[entry[0]].error = false;
				}
			} else if (formObject[entry[0]].pattern) {
				let pattern = new RegExp(formObject[entry[0]].pattern)
				formObject[entry[0]].error = ! pattern.test(entry[1].value);
			} else {
				formObject[entry[0]].error = false;
			}
		});
	};

	const submitHandler = async () =>{
		let missingFields = [];
		let anonId = 'FD-' + uniqid(6);

		// validateFormObject(formFields,missingFields);
		setFormFields(formFields);
		setUpdateKey(Math.random());
		if(missingFields.length>0) {
			showMessage({message: 'All Fields are Required.', type: 'error'});
			return;
		}
		const desiredGarmentType = selectedSex === 'female' ? 'womens_fire' : 'mens_fire';
		let qString = '?client_id='+virtualSizerClient
			+'&user_key='+virtualSizerKey
			+'&anon_id='+anonId
			+'&desired_brand=' +virtualSizerBrand
			+'&desired_garment_type='+desiredGarmentType
			+'&';
		qString += getQueryString(formFields);

		let isError = false;

		Object.keys(formFields).forEach((key) => {
			if (formFields[key].error) {
				isError = key;
			}
		});

		if (isError) {
			if (isError === 'bra_size') {
				showMessage({
					message: 'Bra size must be two digits followed by a letter! (e.g. 32B)',
					type: 'error'
				});
			} else {
				showMessage({
					message: formFields[isError].label + ' is invalid! Please update your entry and try again.',
					type: 'error'
				});
			}

			return;
		}

		try {
			let response = await axios.get(virtualSizerUrl+qString);
			let goodMatches = response.data.size_recommendations.good_matches;
			let dimensions = response.data.dimensions;
			if(goodMatches.length>0){
				let match = goodMatches[0];
				match.dimensions = dimensions;
				match.anon_id = anonId;

				setSizeResults(match);

				let fields = {};

				Object.keys(formFields).forEach((key) => {
					if (key === 'height') {
						fields[key] = convertFeetToInches(formFields[key].value);
					} else {
						fields[key] = formFields[key].value;
					}
				});

				fields.anon_id = anonId;

				await axios.post(
					recordSizeEndpoint,
					{
						params: fields,
						results: match
					},
					{
						headers: {
							'Api-Key': recordSizeApiKey
						}
					}
				);

				scrollToTop();
			}else{
				showMessage({message:'Unable to get good size match.',type:'error'})
			}
		} catch (e) {
			let specifics = e.response?.data?.message?.specifics;
			if (specifics) {
				showMessage({message:specifics[0].field + ' ' + specifics[0].message, type: 'error'});
			} else {
				showMessage({message:'An unknown error occurred. Check the information you entered or contact Firedex for assistance',type:'error'});
			}
		}
	};

	useEffect(()=>{
		if(selectedSex === 'female'){
			formFields.body_type.optionsKey = 'bodyTypeFemale';
		}else{
			formFields.body_type.optionsKey = 'bodyType';
		}
		setFormFields(formFields);
		setUpdateKey(Math.random());
	},[selectedSex])

	return (
		<div className={'sizer-content-container'}>
			<div className={'form-scroll-box'}>
				<div className={'form-container'}>
					<h1 className={'form-title'}>Virtual Sizer</h1>
					<p className={'form-description'}>
						Please complete the
						following form accordingly.
						Thank you for your interest in Fire-Dex!
					</p>
					<div className={'field-list'}>
						{Object.entries(formFields).map((entry,index)=>{
							if (!entry[1] || (entry[1].sexRequired && entry[1].sexRequired !== selectedSex)){
								return null;
							}
							if(entry[1].optionsKey && entry[1].type===fieldTypes.select){
								return (
									<label key={'size'+index}>
										<span className={'title'}>{entry[1].label}</span>
										<StyledSelect
											options={formOptions[entry[1].optionsKey]}
											changeHandler={updateFormField}
											inputDisabled={false}
											field={entry}
										/>
									</label>
								)
							}else if(entry[1].optionsKey && entry[1].type && entry[1].type===fieldTypes.imageSelect){
								return(
									<label key={'size'+index} className={'full-row image-option-select'}>
										<span className={'title'}>{entry[1].label}</span>
										<ImageSelect
											options={formOptions[entry[1].optionsKey]}
											field={entry}
											changeHandler={updateFormField}
										/>
									</label>
								)
							}else if(entry[1].type && entry[1].type===fieldTypes.number){
								return (
									<label key={'size'+index}>
										<span className={'title'}>{entry[1].label}</span>
										<StyledNumberInput
											field={entry}
											changeHandler={updateFormField}
										/>
									</label>
								)
							}
							return(
								<label key={'size'+index}>
									<span className={'title'}>{entry[1].label}</span>
									<div className={'marked-input '+ ((entry[1].error || !entry[1].value)?'invalid':'valid')}>
									<input name={'size'+entry[0]} className={((entry[1].error || !entry[1].value)?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].placeholder || entry[1].label} onChange={(e)=>{updateFormField(entry[0],e.target.value)}} />
									</div>
								</label>
							)
						})
						}
					</div>
				</div>
				<div className={'row tablet-down form-submit'}>
					{!sizeResults &&
					<button onClick={()=>{submitHandler()}} className={'submit-button'}>Submit</button>
					}
				</div>
			</div>
			<div className={'size-results'}>
				<div className={'results-container '+(!sizeResults?'empty':'')}>
					{!sizeResults &&
						<>
							<h3 className={'desktop-up'}>
								Enter your information
								On the left to view Your
								recommended sizing.
							</h3>
							<h3 className={'tablet-down'}>
								Enter your information below
								to view Your recommended sizing.
							</h3>
						</>
					}
					{sizeResults &&
						<>
							<h3>Your Recommended Sizing:</h3>
							<div className={'size-info'}>
								<p>Here are your recommended sizes:</p>
								<div className={'size-table'}>
									<div className={'size-row'}>
										<span>ID:</span>
										<span>{sizeResults.anon_id}</span>
									</div>
									<div className={'size-row'}>
										<span>Coat Length:</span>
										<span>{sizeResults.coat_length}"</span>
									</div>
									<div className={'size-row'}>
										<span>Coat Size:</span>
										<span>{sizeResults.coat_size}"</span>
									</div>
									<div className={'size-row'}>
										<span>Pant Inseam:</span>
										<span>{sizeResults.inseam}"</span>
									</div>
									<div className={'size-row'}>
										<span>Pant Waist:</span>
										<span>{sizeResults.pant_waist}"</span>
									</div>
									{sizeResults.dimensions?.hip_circum &&
										<div className={'size-row'}>
											<span>Hip Circumference:</span>
											<span>{Math.round(sizeResults.dimensions.hip_circum)}"</span>
										</div>
									}
									<div className={'size-row'}>
										<span>Sleeve Length:</span>
										<span>{sizeResults.sleeve_length}"</span>
									</div>
								</div>
							</div>
						</>
					}

				</div>
				<div className={'row desktop-up'}>
					{!sizeResults &&
						<button onClick={submitHandler} className={'submit-button'}>Submit</button>
					}
				</div>
				{sizeResults &&
					<div className={'row spread'}>
						<button className={'submit-button'} onClick={() => {
							clearForm();
						}}>Clear Selections
						</button>
						<button className={'submit-button'} onClick={submitHandler}>Re-Submit</button>
					</div>
				}
			</div>
		</div>
	)
}
